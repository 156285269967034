.content {
  color: #23036A;
  padding-top: 3% !important;
  padding-bottom: 3% !important;
  h6 {
    font-weight: bold;
    font-size: 3.0em;
  }

  h5 { font-weight: 300;
  font-size: 1.3em; }

  p {
    font-size: 0.9em;
  }
}

.title {
  font-weight:bold;
}

.expedition_headshot {
  flex: 0 0 auto;
  width: 12%;
}