.content {
    color: #23036A;
    padding-top: 3% !important;
    padding-bottom: 50% !important;
    h6 {
      font-weight: 300;
      font-size: 2.5em;
    }
  
    h5 { 
        font-weight: bold;
        font-size: 1.0em; 
        margin-top: 2em;
        margin-bottom: 1em;
    }

    h4 {
        margin-top: 1em;
        font-size: 1.2em; 
        font-weight: bold; 
    }
  
    p {
      font-size: 1.1em;
    }
  }
  
  .title {
    font-weight:bold;
  }
  .subtext {
      font-size: 0.8em !important;
  }

  .sidebarArticle {
      font-size: 0.9em !important;
      color: rgb(35, 3, 106);
      padding: 0;
      margin-bottom:1rem;
  }