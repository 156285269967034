.headerSection {
    //background-image: url('./header-bg.svg');
    // min-height: 70vh;
}

.img_carousel_item {
  width: 80%;
  max-width: 320px;
}

.textSection {
    margin-top: 3em;
    margin-bottom: 3em;
}

.section {
    //margin-top: 5em;
    padding-bottom: 5em;
}

.titleBold {
    font-weight: bold;
    font-size: 36px;
}

.derp {
  color: rgb(237, 245, 253)
}

.soul_grey_wrapper {
  position: relative;
}

.soul_grey {
  object-fit: contain;
  /* height: 100%; */
  width: 100%;
  position: absolute;
  bottom: 0px;
}

.soulRedRight {
  width:100%;
  max-width: 300px;
  float: right;
}



.missionControl {
    color: #23036A;
    h6 {
      font-weight: bold;
      font-size: 1.3em;
    }
  
    h5 { font-weight: 300;
    font-size: 1.3em; }
  
    p {
      font-size: 0.9em;
    }
  }

  .joinUs {
    color: #23036A;
    padding-left: 1rem;
    padding-right: 1rem;
    h5 {
        font-size: 1em;
        font-weight: bold;
    }
  }

  .roadmap {
    color: #23036A;
    padding-left: 1rem;
    padding-right: 1rem;
    h6 {
        font-weight: bold;
        font-size: 1.3em;
      }
    
      h5 { font-weight: 300;
      font-size: 1.3em; }
    
      p {
        font-size: 0.9em;
      }

    hr {
        border: 1px solid #2c9cff;
    }
  }

  .roadmapFlex {
      width: 50%;
  }

.salmon_sphere {
  position: absolute;
  bottom: -210px;
  left: -400px;
  width: 400px;
}

.soulPods {
  margin-left: -25px;
}

.flip {
  transform: scaleX(-1);
}

.h1_backdrop {
  background-color: rgba(0, 0,0, 0.2);
  padding: 0px 20px 10px;
  margin: 0 auto;
  margin-top: 20px;
  display: inline-block;
}