$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px
);

.sphere {
  position: absolute;
  width: 300px;

  display: none;

  @media (min-width: 576px) {
    display: block;
  }

  // display: none;
  // bottom: -150px;

  &.left {
    // display: none;
    left: -150px;
    @media (min-width: 768px) {
      left: -5vw;
    }
    @media (min-width: 992px) {
      left: -100px;
    }
  }
  &.right {
    right: -150px;
    // @media (min-width: 768px) {
    //   right: -45vw;
    // }
    @media (min-width: 992px) {
      right: -150px;
    }
  }

  .img {
    width: 100%;
  }
}