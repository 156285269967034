.my-carousel {
  overflow: hidden;
  width: 25%;
  flex-grow: 2;
  @media (min-width: 600px) {
    flex-grow: 1;
  }
}

.inner {
  white-space: nowrap;
  transition: transform 0.3s;
  width: 100%;
  /* padding-top: 50px; */
  padding-top: 0px;
}

.my-carousel-item {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  /* height: 250px; */
  /* background-color: green; */
  color: #fff;
  vertical-align: bottom;
}

.arrow {
  flex-grow: 1;

  @media (min-width: 600px) {
    flex-grow: 1;
  }

  img {
    max-width: 60px;
  }
}

.align_right {
  text-align: right;
}