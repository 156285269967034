.content {
  color: #23036A;
  padding-top: 3% !important;
  padding-bottom: 50% !important;
  h6 {
    font-weight: bold;
    font-size: 3.0em;
  }

  h5 { 
      font-weight: bold;
      font-size: 1.0em; 
      margin-top: 2em;
      margin-bottom: 1em;
  }

  h4 {
      margin-top: 1em;
      font-size: 2.0em; 
      font-weight: 300; 
  }

  p {
    font-size: 1.1em;
  }
}

.title {
  font-weight:bold;
}
.subtext {
    font-size: 0.8em !important;
}

.article_link {
  color: rgb(35, 3, 106);
}