.textSection {
    margin-top: 5em;
    margin-bottom: 5em;
    h1 {
      font-weight: bold;
      font-size: 1.6em;
      color: #23036A;
    }
  
    p {
      font-size: 0.9em;
      color: #23036A;
    }
}

.section {
    //margin-top: 5em;
    padding-bottom: 5em;
}

.titleBold {
    font-weight: bold;
    font-size: 36px;
}


  .mainHeader {
    padding-top: 5em;
    padding-bottom: 7em;
    color: #23036A;
    h6 {
      font-weight: bold;
      font-size: 3.3em;
    }
  
    h5 { font-weight: 300;
    font-size: 1.3em; }
  
    p {
      font-size: 0.9em;
    }
  }



 