.headerSection {
    min-height: 70vh;
}
.textSection {
    margin-top: 5em;
    margin-bottom: 5em;
}
.headerStyle {
    color: #23036A;
    h6 {
      font-weight: bold;
      font-size: 3.3em;
    }
  
    h5 { font-weight: 300;
    font-size: 1.3em; }
  
    p {
      font-size: 1.1em;
    }
  }
.salmon_sphere {
  position: absolute;
  top: -10px;
  left: -400px;
  width: 400px;
}

.floating_border_button.floating_border_button {
  margin-top: -50px;
  height: 0px;
  position: relative;
  top: 80px;

  .floating_btn {
    background-color: white !important;
    font-size: 1.3em;
    border-radius: 20px;
    border-color: rgb(44, 156, 255) !important;
  }
}