.video_container {
  // height: calc(100vh - 100px);
  // // height: 100%;
  // max-height: 100vh;
  
  height: 55vw;
  max-height: 95vh;
  min-height: 60vh;



  // @media (min-width:500px) {
  //   height: 55vw;
  //   max-height: 95vh;
  // }
}

.video_overlay {
  z-index: 30;
  max-height: 95vh;
  overflow: hidden;
  min-height: 70%;
  justify-content: space-between;
  // height: calc(100vh - 100px);
  // object-fit: cover;

  // @media (min-width:500px) {
  //   height: 55vw;
  //   max-height: 95vh;
  // }
}

.video_h1 {
  display: inline-block;
  margin-left: auto;
  margin-right: auto;
  background-color: rgba(0, 0, 0, 0.3);
  padding: 8px 12px;
  border-radius: 8px;
}

.video_element {
  z-index: -1;
  height:100%;
  width:100%;
  // object-fit: scale-down;
  // width: 200%;
  object-fit: cover;
  
  // @media (min-width: 600px) {
  //   width: 100%;
  //   // object-fit: cover;
  //   // object-fit: contain;
  // }

}