 .container {
    max-width: 80%;
    margin: auto;
  }
  .containerSingle {
    max-width: 60%;
    margin: auto;
  }
  .containerEars {
    max-width: 100%;
    margin: auto;
  }
  .imageSize.imageSize {
    width: 100%;
    height: auto;
  }
  .button {
    background-color: transparent !important;
    border: none !important;
  }
.slide {
  padding-left: 25%;
  padding-right: 25%;
}

.slide_is_visible {
  background:'red' !important;
}

