.content {
    color: #23036A;
    padding-top: 3% !important;
    padding-bottom: 3% !important;
    h6 {
      font-weight: bold;
      font-size: 3.0em;
    }
  
    h5 { font-weight: 300;
    font-size: 1.3em; }
  
    p {
      font-size: 0.9em;
    }
  }
  
  .title {
    font-weight:bold;
  }
  
  .expedition_headshot {
    flex: 0 0 auto;
    width: 12%;
  }

  .textSection {
    margin-top: 2em;
    margin-bottom: 2em;
    h1 {
      font-weight: bold;
      font-size: 1.6em;
      color: #23036A;
    }
  
    p {
      font-size: 1.1em;
      color: #23036A;
    }
}

.textSectionLight {
  margin-top: 2em;
  margin-bottom: 2em;
  h1 {
    font-weight: bold;
    font-size: 1.6em;
    color: #fff;
  }

  p {
    font-size: 1.1em;
    color: #fff;
  }

  h5 {
    color:#fff;
  }
}

.section {
    //margin-top: 5em;
    padding-bottom: 5em;
}

.titleBold {
    font-weight: bold;
    font-size: 36px;
}


  .mainHeader {
    padding-top: 5em;
    padding-bottom: 7em;
    color: #23036A;
    h6 {
      font-weight: bold;
      font-size: 3.3em;
    }
  
    h5 { font-weight: 300;
    font-size: 1.3em; }
  
    p {
      font-size: 0.9em;
    }
  }

  .roadmap {
    color: #23036A;
    padding-left: 1rem;
    padding-right: 1rem;
    h6 {
        font-weight: bold;
        font-size: 1.3em;
      }
    
      h5 { font-weight: 300;
      font-size: 1.3em; }
    
      p {
        font-size: 1.1em;
      }

    hr {
        border: 1px solid #2c9cff;
    }
  }

  .roadmapFlex {
      width: 100%;
  }

  .mode {
    width:100%;
    h6 {
      font-weight: bold;
      font-size: 1.2em;
    }
    p {
      font-size: 1.1em;
    }
  }

  .dark {
    color: #23036A; 
    border-bottom: 1px solid #23036A; 
  }
  .light {
    color: #ffffff; 
    border-bottom: 1px solid #fff;
  }

  .img_carousel_item {
    width: 80%;
    max-width: 320px;
  }

.differentSizes {
  // wish I could customize per-carousel
  position: absolute;
  bottom: 0px;
}


.ears.ears {
  position: absolute;
  bottom: 0px;
  width: 90%;

}