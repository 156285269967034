.textSection {
    margin-bottom: 5em;
}

.section {
    //margin-top: 5em;
    padding-bottom: 5em;
}
.soulPods {
    //margin-left: -25px;
  }

.narrow_hide {
    display: none;

    @media(min-width:700px) {
        display: inherit;
    }
}