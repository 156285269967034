//IMPORT DEFAULT FONTS
@import url('https://fonts.googleapis.com/css2?family=Jura:wght@300;400;500;600;700&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');

//BOOTSTRAP CUSTOMIZATIONS
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";

//Bootstrap theme customizations
$btn-border-radius: 1.2rem;        
$btn-border-radius-sm: 1.5rem;   
$btn-border-radius-lg: 2rem;  
$navbar-light-color: #2C9CFF;
$font-family-base: 'Ubuntu', sans-serif;
$primary: #2C9CFF;

h1 {
    font-family: 'Jura', sans-serif;
}

.btn-primary {
    color: #fff !important;
    background: #2C9CFF !important;
}

.btn-secondary {
    color: #2C9CFF !important;
    background-color: #F2E7FE !important;
    border-color: #fff !important;
}

.btn-outline-secondary {
    color: #fff !important;
    border-color: #fff !important;
}

.text-color-soul {
    color: #23036A;
}

main {
    overflow-x: hidden;
}

.row {
    position: relative;
}


//IMPORT BOOTSTRAP SASS WITH CHANGES
@import 'bootstrap/scss/bootstrap';


