$lavander: #627993;
$dusty: #96858F;
$overcast: #9099A2;
$paper: #d5d5d5;

$primary: $lavander;
$green: #629362;

$darkpurple: #1C1F50;

.hero {

}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.header {
  display: grid;
  grid-template-columns: 1fr;
  border-bottom: 1px solid $primary;

  @media (min-width: 764px) {
    grid-template-columns: 1fr 1fr;

  }
  .header_half {
    padding: 0px 20px;
    // padding
  }

  .header_description {
    font-size: 1.2em;
  }
}

.warning {
  background: lighten(red, 30);
  padding: 20px;
  font-weight:800;
}

.form_warning_wrapper {
  margin-bottom: 10px;
}

.form_warning {
  background: lighten(red, 30);
  padding: 2px;
  font-weight:800;
}

@mixin button($color, $textColor: white) {
  box-sizing: border-box;
  border-width: 0px;
  border-style: solid;
  border-radius: 6px;
  padding: 15px;
  background-color: $color;
  color: $textColor;
  font-size: 1em;
  font-weight: 800;
  cursor: pointer;
  // margin: 10px 0px;

  &:hover {
    background-color: lighten($color, 10);
  }

  &:active, &:focus {
    background-color: darken($color, 5);
  }
}

.connectWalletOld {
  @include button($primary);
}

.expandButton {
  @include button($primary);
  float: right;
}


.createDiamondSection {
  padding: 20px;
  border-bottom: 1px solid #627993;

  .createDiamond {
    @include button($green);
    margin: 0px;
    &:disabled {
      background-color: #555;
      cursor: not-allowed;
    }
  }

  .cutInDiamond {
    @include button($green);
    margin-top: 20px;
    &:disabled {
      background-color: #555;
      cursor: not-allowed;
    }
  }

  .createSplitterForm {
    background-color: $paper;
    padding: 20px;
    border-bottom: 1px solid $primary;
  }

  .addrbox {
    margin-top: 15px;
    // display: inline-block;
    // clear: left;
    input:disabled {
      pointer-events: none;
    }

    label {
      margin-right: 5px;
    }
    .addressInput {
      width: 320px;
    }
    .addressValidLabel {
      margin-left: 10px;
    }
  }

  .addAddress {
    @include button($primary);
    padding: 10px;
    margin-left: 20px;
    position: relative;
    margin-top: -7px;
  }

  .removeAddress {
    @include button($dusty);
    padding: 10px;
    margin-left: 20px;
    position: relative;
    margin-top: -7px;
  }

  .addressTitle {
    font-size: 1.2em;
    margin-bottom: 5px;
  }

  .successMessage {
    margin-top: 10px;
    font-size: 1.1em;
    border: 1px solid $primary;
    background: lighten($green, 10);
    padding: 20px;
    line-height:1.5;
  }
}

.existingSplittersTitle {
  margin-left: 20px;
  font-size: 1.4em;
}

.singleSplitterWrap {
  background-color: $paper;
  padding: 20px;
  border: 1px solid $primary;
  margin: 20px;

  .singleSplitterHeader {
    display: grid;
    grid-template-columns: 3fr 1fr;

    .endOfGrid {
      justify-self: end;
    }
  }


  .contractAddress {
    font-size: 1.1em;
    font-weight: 800;

    span {
      font-weight: normal;
    }
  }


  .splitterRow {
    display: grid;
    grid-template-columns: 2fr 1fr 1fr 1fr;

    p {
      margin: 5px 0px;
      &.textOnly {
        padding-top: 10px;
      }
    }
  }

  .splitterRowHeader {
    @extend .splitterRow;
    font-weight: 800;
  }
  .releaseFunds {
    @include button($green);
    float:right;
    padding: 10px 20px;
  }
}

.networkSelector {
  position: absolute;
  top: 5px;
  right: 5px;

  border: 2px solid grey;
  border-radius: 5px;
  background-color: white;
  padding: 5px;
  user-select: none;
  cursor: pointer;

  .currentNetwork {
    padding-left: 5px;
    font-weight: 800;
  }

  .networkSelectorDropdown {
    display: none;

    &.showDropdown {
      display: block;
    }

    .networkUrl {
      display: block;
      margin-left: 70px;
      font-size: 1.1em;
    }
  }
}

.homeheader {
  top: 80px;
}

.homeheadertext {
  text-shadow: 3px 1px 0px black;
}

.cta {
  margin-left: 50%;
  width: 40%;
  text-shadow: 3px 2px 0px black;
}


.connectWallet {
  @include button(#FFDB1B, black);
  font-weight: 600;
  margin-top: 15px;
  margin-right: 15px;
  padding: 10px 15px;
}


.constrained {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.roadmap_item {
  display: grid;
  grid-template-columns: 1fr 10fr;
}

.roadmap_side {
  margin-top: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 5px;
}

.icon_wrapper {
  width: 30px;
  height: 30px;
  border-radius: 15px;
  text-align: center;
  padding-left:10px;
  // vertical-align: middle;
  padding-top:10px;
  

  &.done {
    padding-left:5px;
    padding-top:5px;
    background-color: #9BBA54;
  }

  &.soon {
    background-color: #6B51F0;
  }

  &.future {
    background-color: lightgray;
  }
}

.circle_icon {
  width:10px;
  height: 10px;
  background-color: white;
  border-radius: 5px;
}

.pedestal {
  margin-top: 10px;
  flex: 1;
  
  width: 2px;
  border-radius: 3px;
  // margin-left:10px;
  // // vertical-align: middle;
  // margin-right:10px;

  &.done {
    background-color: #9BBA54;
  }

  &.soon {
    background-color: #6B51F0;
  }

  &.future {
    background-color: lightgray;
  }
}

.team_member {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px;
  text-align: center;
}   
