
.nav-h32 {
  height: 32px;
  vertical-align: middle;
  margin-top: 4px;
}

.spaced {
  justify-content: space-between;
  width: 100%;
}